const main = {
  'App Description':
    'SHOPLINE Google 広告フラッグシップ プログラムに参加している場合は、SHOPLINE によって提供される Google 広告のデータと結果をこのページで確認できます。広告残高が不足している場合は、<anchor>広告プランページ</anchor> から広告クレジットを購入し、チャージしてください。',
  marketing_plan_url: 'https://marketing.shoplineapp.com/pages/project-google',
  marketing_plan_url_MYR: 'https://marketing.shopline.my/eliteplan',
  marketing_plan_url_HKD: 'https://adseshop.shoplineapp.com/',
  'Google Ads Analytics': 'Google 広告アナリティクス',
  'Credit Alert': '広告予算残高があとわずかです。',
  'Add value': '広告を継続したい場合は、<anchor>こちらをクリックして</anchor>チャージしてください。',
  DATA_TABLE: {
    DATE: '日付',
    COST: '支出',
    IMPRESSIONS: '露出数',
    CLICKS: 'クリック数',
    CONVERSIONS: 'コンバージョン数（注文数）',
  },
  'LAST 30 DAYS': '(過去 30 日間)',
  balance: '広告予算残高: {{ displayBalance }}',
  'table description': '※解析データは毎晩12時に更新されます',
  'get report failed': '権限が不十分なため、このページを表示できません。広告オペレーター管理者にお問い合わせください。',
};

export default main;
